import { useUserCarts, userCartsAtom } from "components/jotai/cartAtom";
import { useState } from "react";
import { useApi } from "./useApi";

const useDiscounts = storeId => {
	const api = useApi();
	const { userCarts, setUserCarts } = useUserCarts(userCartsAtom);
	const [isLoading, setIsLoading] = useState(false);

	const fetchDiscounts = async () => {
		try {
			setIsLoading(true);
			const params = {
				cart_items: userCarts.find(
					cart => String(cart.storeId) === String(storeId),
				).products,
			};
			const res = await api.getStoreDiscountsApply(storeId, params);
			if (res.ok) {
				const updatedDiscounts = res?.data?.discounts;
				if (!!updatedDiscounts) {
					applyDiscounts(storeId, updatedDiscounts);
				}
			} else {
				throw res.error.message;
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const applyDiscounts = (storeId, discounts = []) => {
		const formatedDiscounts = discounts.map(discount => {
			if (discount.one_time_discount) {
				return {
					...discount,
					discounted_products: [discount.cheapest_discounted_product],
				};
			}
			return discount;
		});
		const updatedCarts = userCarts.map(cart => {
			if (cart.storeId === storeId) {
				return {
					...cart,
					discounts: formatedDiscounts,
					total_new_price:
						formatedDiscounts?.[formatedDiscounts.length - 1]?.total_new_price,
				};
			} else {
				return cart;
			}
		});
		setUserCarts(updatedCarts);
	};

	return {
		isLoading,
		fetchDiscounts,
	};
};
export default useDiscounts;
