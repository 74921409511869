import { MinusSmIcon, PlusSmIcon } from "@heroicons/react/solid";
import { useUserCarts } from "components/jotai/cartAtom";
import React from "react";

const CartAddButton = ({ cart, product, setRemoveItemModal }) => {
	const { addToCart, removeFromCart, getProductCountInCart } = useUserCarts();
	const productCountInCart = getProductCountInCart(
		Number(cart.storeId),
		product,
	);
	return (
		<div className="flex justify-center">
			<div className="isolate inline-flex rounded-md shadow-sm">
				<div
					onClick={e => {
						addToCart(Number(cart.storeId), product, true, "Jane cart");
						e.stopPropagation();
					}}
					className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-1 sm:px-2 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-jane-500 focus:outline-none focus:ring-1 focus:ring-jane-500 cursor-pointer"
				>
					<PlusSmIcon
						className="w-2 h-2 xs:w-3 xs:h-3  sm:w-4 sm:h-4"
						aria-hidden="true"
					/>
				</div>
				<div className="relative inline-flex items-center border border-gray-300 border-l-0 bg-white px-1 sm:px-2 py-1 text-sm font-medium text-gray-500">
					{productCountInCart}
				</div>
				<div
					onClick={e => {
						if (productCountInCart === 1) {
							setRemoveItemModal({
								isOpen: true,
								product: product,
							});
						} else {
							removeFromCart(Number(cart.storeId), product);
						}
						e.stopPropagation();
					}}
					className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-1 sm:px-2 py-1 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-jane-500 focus:outline-none focus:ring-1 focus:ring-jane-500 cursor-pointer"
				>
					<MinusSmIcon
						className="w-2 h-2 xs:w-3 xs:h-3 sm:w-4 sm:h-4"
						aria-hidden="true"
					/>
				</div>
			</div>
		</div>
	);
};

export default CartAddButton;
